import { send } from "src/utils/fetch";
import { StateCode } from "src/utils/usStates";

export type StreetAddress = {
  address: {
    street: string;
    city: string;
    state: string;
    postalCode: string;
    country: string;
  };
};

export type UsStreetAddress = {
  address: {
    street: string;
    city: string;
    state: StateCode;
    postalCode: string;
    country: string;
  };
};

export function postValidateAddress(
  address: UsStreetAddress
): Promise<JsonObject>;
export function postValidateAddress(
  address: StreetAddress
): Promise<JsonObject> {
  const url = "/api/insurance/v1/validate_address/";
  return send("POST", url, address);
}
