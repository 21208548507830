import { useCallback, useEffect, useMemo } from "react";
import { AdditionalErrorFields } from "src/components/core/AddressForm/AddressForm";
import {
  setAddress,
  toggleSameAsBillingAddress,
} from "src/utils/redux/slices/checkoutPhysicalAddress/checkoutPhysicalAddress";
import { typedKeys } from "src/utils/typeWrappers";
import { isValidZipCode } from "src/utils/validate";
import {
  useAppDispatch,
  useCheckout,
  useCheckoutPhysicalAddress,
  useCoreState,
  useCoreStore,
} from "../redux";
import {
  failCountryValidation,
  failNoPOBoxValidation,
  failStateValidation,
  failZipCodeMedValidation,
} from "src/utils/redux/slices/checkoutPhysicalAddress/action-creators";
import i18n from "src/locales";
import { BillingAddress, paymentTypes } from "src/utils/redux/slices/checkout";
import { calculateSalesTax } from "../billing-info";
import {
  acceptedNoomClinicalOffer,
  hasSelectedCompoundedPlan,
  isInAppMedUpgrade,
  isMedAsATierEnabled,
} from "src/utils/userSegment/features";
import getStore from "src/utils/redux/store";
import {
  selectResolvedPhysicalAddress,
  validatePhysicalAddressEligibility,
} from "src/utils/redux/slices/checkoutPhysicalAddress/address-selectors";
import zipState from "zip-state";
import {
  BrandedPlanIneligibleStates,
  CompoundedPlanIneligibleStates,
} from "@components/refactored-survey/question-sets/insurance-survey-questions/utils/insuranceConstants";
import { trackEvent } from "src/utils/api/tracker";
import { requestAddressValidation } from "src/utils/redux/slices/checkoutPhysicalAddress/address-thunks";
import { getFullName } from "src/utils/name";

export function requiresPhysicalAddress(state = getStore().getState()) {
  return acceptedNoomClinicalOffer(state);
}

export function usePhysicalAddressForm() {
  const store = useCoreStore();
  const {
    address,
    isSameAsBillingAddress,
    addressInvalid,
    suggestedAddress,
    chosenModalAddress,
    acceptedSuggestedAddress,
    shouldUpdateFormAddress,
  } = useCheckoutPhysicalAddress();
  const checkout = useCheckout();

  const coreState = useCoreState();
  const dispatch = useAppDispatch();

  const showNameFields = isInAppMedUpgrade() && hasSelectedCompoundedPlan();

  const updatePhysicalAddress = useCallback(
    (newAddr: BillingAddress) => {
      const newName = getFullName(newAddr.firstName, newAddr.lastName);

      const addr = {
        ...newAddr,
        ...(newName && {
          name: newName,
        }),
      };

      const { region, zipcode } = addr;
      dispatch(setAddress(addr));
      if (
        (region && address?.region !== region) ||
        (zipcode && address?.zipcode !== zipcode)
      ) {
        calculateSalesTax(store);
      }
    },
    [dispatch, address, store]
  );

  const togglePhysicalAddressSameAsBilling = useCallback(
    (sameAsBillingAddress: boolean) => {
      dispatch(toggleSameAsBillingAddress(sameAsBillingAddress));
      calculateSalesTax(store);
    },
    [dispatch, store]
  );

  useEffect(() => {
    if (checkout.paymentType !== paymentTypes.CREDIT_CARD) {
      togglePhysicalAddressSameAsBilling(false);
    }
  }, [checkout.paymentType, togglePhysicalAddressSameAsBilling]);

  const shouldShowSameAsBillingAddressToggle = useMemo(() => {
    return (
      checkout.paymentType === paymentTypes.CREDIT_CARD && !checkout.walletPay
    );
  }, [checkout.paymentType, checkout.walletPay]);

  const notNeededPhysicalAddress = useMemo(() => {
    return !requiresPhysicalAddress(store.getState()) || isSameAsBillingAddress;
  }, [isSameAsBillingAddress, store]);

  const getPhysicalAddressZipCode = () => {
    return (
      notNeededPhysicalAddress
        ? checkout.billingAddress?.zipcode
        : address?.zipcode
    )?.split("-")[0];
  };

  function getNoPOBoxInAddressValidation() {
    if (hasSelectedCompoundedPlan() && acceptedNoomClinicalOffer()) {
      // match "po box 123", "P.O. box", "Post office box", "post box", "postal box"
      // should not match "p box", "pobox", "inspo boxley"
      // could let in mistakes such as "po box123" or "post office. box"
      const PO_BOX_REGEX =
        // eslint-disable-next-line no-useless-escape
        /^ *((#\d+)|((box|bin)[-. \/\\]?\d+)|(.*p[ \.]? ?(o|0)[-. \/\\]? *-?((box|bin)|b|(#|n|num|number)?\d+))|(p(ost|ostal)? *(o(ff(ice)?)?)? *((box|bin)|b)? *(#|n|num|number)*\d+)|(p *-?\/?(o)? *-?box)|post office box|((box|bin)|b) *(#|n|num|number)? *\d+|(#|n|num|number) *\d+)/i;

      const enteredAddress = selectResolvedPhysicalAddress(coreState);
      const isAddressPOBox =
        PO_BOX_REGEX.test(enteredAddress.address1) ||
        PO_BOX_REGEX.test(enteredAddress.address2);

      if (isAddressPOBox) {
        dispatch(failNoPOBoxValidation());
        return ["poBoxAsAddress"];
      }
    }

    return [];
  }

  function getZipCodeStateEligibleValidation() {
    if (requiresPhysicalAddress(coreState) && isMedAsATierEnabled()) {
      const zipcode = getPhysicalAddressZipCode();

      if (!zipcode) {
        return [];
      }

      const state = zipState(zipcode);
      const isCompoundedPlan = hasSelectedCompoundedPlan();
      const inEligibleStates = isCompoundedPlan
        ? CompoundedPlanIneligibleStates
        : BrandedPlanIneligibleStates;

      if (state in inEligibleStates) {
        dispatch(failZipCodeMedValidation());
        return ["postcode"];
      }
    }
    return [];
  }

  function getMedLegacyAddressEligibilityValidation() {
    if (requiresPhysicalAddress() && !isMedAsATierEnabled()) {
      const result = validatePhysicalAddressEligibility(coreState);
      if (result.country) {
        trackEvent("NoomClinicalCountryValidationFailed");
        dispatch(failCountryValidation());
        return ["country"];
      }
      if (result.region) {
        trackEvent("NoomClinicalStateValidationFailed");
        dispatch(failStateValidation());
        return ["state"];
      }
    }
    return [];
  }

  function enteredAddressMatchesChosenAddress() {
    if (Object.keys(chosenModalAddress).length > 0) {
      const enteredAddress = selectResolvedPhysicalAddress(coreState);
      return !Object.keys(chosenModalAddress).some(
        (addressField) =>
          chosenModalAddress[addressField] !== enteredAddress[addressField]
      );
    }
    return false;
  }

  async function getPhysicalAddressValidation() {
    if (acceptedSuggestedAddress && enteredAddressMatchesChosenAddress()) {
      return [];
    }

    try {
      await dispatch(requestAddressValidation({})).unwrap();
    } catch {
      return ["invalidPhysicalAddress"];
    }
    return [];
  }

  const validateForm = useCallback((): AdditionalErrorFields => {
    if (notNeededPhysicalAddress) {
      return null;
    }
    const baseValidation = {
      address1: !address.address1 ? {} : null,
      city: !address.city ? {} : null,
      region: !address.region ? {} : null,
      postcode: !isValidZipCode(address.zipcode, address.country) ? {} : null,
      country: !address.country ? {} : null,
      firstName: !address.firstName && showNameFields ? {} : null,
      lastName: !address.lastName && showNameFields ? {} : null,
    };

    return {
      ...baseValidation,
      ...getTrErrorState({ address, suggestedAddress, addressInvalid }),
    };
  }, [address, addressInvalid, suggestedAddress, notNeededPhysicalAddress]);

  const hasPhysicalAddressErrors = useCallback(() => {
    const errors = validateForm();
    return errors && typedKeys(errors).some((key) => errors[key]);
  }, [validateForm]);

  return {
    updatePhysicalAddress,
    address,
    isSameAsBillingAddress,
    togglePhysicalAddressSameAsBilling,
    validateForm,
    hasPhysicalAddressErrors,
    shouldShowSameAsBillingAddressToggle,
    getPhysicalAddressZipCode,
    getNoPOBoxInAddressValidation,
    getZipCodeStateEligibleValidation,
    getMedLegacyAddressEligibilityValidation,
    getPhysicalAddressValidation,
    acceptedSuggestedAddress,
    shouldUpdateFormAddress,
    showNameFields,
  };
}

export function getTrErrorState({
  address,
  suggestedAddress,
  addressInvalid,
}: {
  address: BillingAddress;
  suggestedAddress: Partial<BillingAddress>;
  addressInvalid: boolean;
}): AdditionalErrorFields {
  // TR validation request didn't return invalid, or they already started correcting
  if (!addressInvalid) {
    return null;
  }
  // No suggestions available; give them a generic invalid message
  if (!suggestedAddress || Object.keys(suggestedAddress).length === 0) {
    return {
      address1: {
        type: "notFound",
        message: i18n.t("payment:homeAddressErrors:notFound"),
      },
    };
  }
  // Otherwise, give them a message for the fields that differ
  const keys = ["address1", "city", "region", "zipcode"];
  const ret: AdditionalErrorFields = {};
  keys.forEach((key) => {
    if (suggestedAddress[key] && suggestedAddress[key] !== address[key]) {
      const errorKey = key === "zipcode" ? "postcode" : key;
      ret[errorKey] = {
        type: "notRecognized",
        message: i18n.t("payment:homeAddressErrors:notRecognized"),
      };
    }
  });
  return ret;
}
