import {
  Action,
  Block,
  PageList,
  PageListEntry,
  PageSet,
  Survey,
  SessionSurveyKey,
} from "src/pageDefinitions/types";
import {
  getPageAtLocation,
  getPageLocation,
  PageLocation,
} from "src/pageDefinitions/pageSets";
import invariant from "tiny-invariant";
import { CoreReduxState } from "src/utils/redux/store";
import { conditionsPass } from "src/pageDefinitions/conditions";
import { visitPageSet } from "src/pageDefinitions/pageSets/visit";
import { captureMessage } from "@sentry/browser";
import i18n from "@i18n";
import { captureMessageWithExtras } from "src/utils/error";

/**
 * Finds all survey-related pages that are defined within page set at given point.
 */
export function getPageSetSurvey(
  pageSet: PageSet,
  page: PageListEntry,
  state?: CoreReduxState
): {
  name: string;
  surveyNameSpace: Survey["surveyNameSpace"];
  loggedInHeader?: boolean;
  hideHeader?: boolean;
  hideHeaderLogo?: boolean;
  hideHeaderInsideWebView?: boolean;
  blockLocation: PageLocation;
  pages?: PageList;
  props: Block["props"];
  onAnswered?: ReadonlyArray<Action>;
  combineProgress?: string;
  skipSeen?: "prior-layer" | false;
  titleOverride?: string;
  sessionSurveyKey?: SessionSurveyKey;
} {
  const location = pageSet && page && getPageLocation(pageSet, page);
  const blockLocation = location?.slice(0, 1);
  const block = getPageAtLocation(pageSet, blockLocation);

  // Provide basic defaults if we are not currently in a well defined
  // survey.
  if (!block || !("name" in block)) {
    return {
      name: undefined,
      blockLocation: undefined,
      surveyNameSpace: `userData.${pageSet?.layer}-survey`,
      loggedInHeader: false,
      props: {},
    };
  }

  invariant(block.name, `Survey block as location ${location} has no name`);

  // Include pages if a state is passed to eval against.
  let pages;
  if (state) {
    pages = block.block.filter(
      (pageToFilter) => conditionsPass(pageToFilter.conditions, state).score
    );
  }

  // If no known survey is provided, scope everything to the layer
  const resolvedNamespace =
    block.surveyNameSpace || `userData.${pageSet.layer}-survey`;

  return {
    ...block,
    surveyNameSpace: resolvedNamespace,
    props: block.props || {},
    blockLocation: location.slice(0, -1),
    pages,
  };
}

export function findSourceLocation(pageSet: PageSet, sourceId: string) {
  // If a source id was passed, jump to that call before moving to the next
  let sourceLocation: PageLocation;
  if (sourceId) {
    visitPageSet(pageSet, ({ entry }) => {
      if ("goto" in entry) {
        const layerCall = entry.goto.layer || entry.goto.pushLayer;
        if (layerCall && layerCall.includes(sourceId)) {
          // Sanity logging
          if (sourceLocation) {
            captureMessage("Multiple source locations found", {
              contexts: {
                pushLayer: {
                  sourceId,
                  pageSet: pageSet.id,
                },
              },
            });
          }

          sourceLocation = getPageLocation(pageSet, entry);
        }
      }
    });

    // Sanity logging
    if (!sourceLocation) {
      captureMessage("sourceId not found", {
        contexts: {
          pushLayer: {
            sourceId,
            pageSet: pageSet.id,
          },
        },
      });
    }
  }
  return sourceLocation;
}

export function findEntryPointLocation(pageSet: PageSet, entryPoint: string) {
  let entryPointLocation: PageLocation;

  if (entryPoint) {
    visitPageSet(pageSet, ({ entry }) => {
      if ("entryPoints" in entry) {
        if (entry.entryPoints.includes(entryPoint)) {
          // Sanity logging
          if (entryPointLocation) {
            captureMessageWithExtras(
              "Multiple instances of the same entryPoint found in the pageSet",
              {
                contexts: {
                  pushLayer: {
                    entryPoint,
                    pageSet: pageSet.id,
                  },
                },
              }
            );
          }

          entryPointLocation = getPageLocation(pageSet, entry);
        }
      }
    });

    // Sanity logging
    if (!entryPointLocation) {
      captureMessageWithExtras("entryPoint not found", {
        contexts: {
          pushLayer: {
            entryPoint,
            pageSet: pageSet.id,
          },
        },
      });
    }
  }

  return entryPointLocation;
}

// Gets key-value pairs for survey legend.
// Survey legend is used for score questions, explaining what low and high scores mean for the particular question
export function getSurveyLegendWithTranslationKeys(
  questionId,
  overrides?: {
    namespace?: string;
    type?: string;
  }
) {
  return getSurveyOptionsWithTranslationKeys(questionId, ["left", "right"], {
    ...overrides,
    type: "legend",
  });
}

// Gets text or translation key.
// Returns the corresponding translation or text
export function getTransationText(i18nKeyOrText) {
  return typeof i18nKeyOrText === "string" && i18nKeyOrText.startsWith("t:")
    ? i18n.t(i18nKeyOrText.substring(2))
    : i18nKeyOrText;
}

// Gets key-value pairs for survey question options.
export function getSurveyOptionsWithTranslationKeys<
  T extends string,
  U extends string
>(
  questionId: string,
  options: T[],
  overrides?: {
    namespace?: string;
    type?: string;
  }
): Record<T & U, string> {
  const path = { namespace: "survey:questions", type: "options", ...overrides };
  const translated: Record<string, string> = {};
  options.forEach((option) => {
    translated[
      option
    ] = `t:${path.namespace}:${questionId}:${path.type}:${option}`;
  });
  return translated;
}

export const HmNamespace = "surveyHM:questions";
