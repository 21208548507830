import {
  UsStreetAddress,
  postValidateAddress,
} from "src/components/address-validation/api";
import { NoomError } from "src/utils/error/NoomError";
import { errorConstants } from "src/utils/services/PurchaseErrorMessages";
import { getFullState } from "src/utils/usStates";
import { createAppAsyncThunk } from "../../async-thunk";
import { suggestAddress, returnedInvalidAddress } from "./action-creators";
import { selectResolvedPhysicalAddressShortRegion } from "./address-selectors";
import { trackEvent } from "src/utils/api/tracker";

// Assumes United States
export const requestAddressValidation = createAppAsyncThunk(
  "checkoutPhysicalAddress/validate",
  async (_, thunkApi) => {
    const { dispatch, getState } = thunkApi;
    const address = selectResolvedPhysicalAddressShortRegion(getState());
    const request: UsStreetAddress = {
      address: {
        street: address.address1,
        city: address.city,
        state: address.region,
        // This validation endpoint does not accept fully-qualified zip codes (eg, "10001-2603"), only
        // the base zip code ("10001"). The autocomplete returns them fully-qualified, so make sure to
        // trim it down to 5.
        postalCode: address.zipcode?.slice(0, 5),
        country: address.country,
      },
    };
    try {
      await postValidateAddress(request);
    } catch (error) {
      const errorData = error.responseData || error;
      if ("correctedAddress" in errorData) {
        const { correctedAddress } = errorData;
        const newAddress = {
          address1: correctedAddress.street,
          city: correctedAddress.city,
          region: getFullState(correctedAddress.state),
          zipcode: correctedAddress.postalCode,
        };
        dispatch(suggestAddress(newAddress));
      } else {
        dispatch(returnedInvalidAddress());
      }
      trackEvent("NoomClinicalPhysicalAddressValidationFailed");
      const err = new NoomError(errorConstants.physicalAddressValidationFailed);
      // Not sure how "codes" are supposed to get onto errors, but purchase.tsx wants them
      (err as any).code = errorConstants.physicalAddressValidationFailed;
      throw err;
    }
  }
);
