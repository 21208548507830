import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { errorConstants } from "src/utils/services/PurchaseErrorMessages";
import { PaymentAddonProduct } from "src/utils/paymentAddon";
import {
  failCountryValidation,
  failStateValidation,
  userAcceptedSuggestedAddress,
} from "./checkoutPhysicalAddress/action-creators";
import {
  failNoomClinicalAgeValidation,
  failMedicareStatusValidation,
} from "./paymentEnrollmentFormActions";

/**
 * Payment type constants
 */
export enum paymentTypes {
  CREDIT_CARD = "CREDIT_CARD",
  PAYPAL = "PAYPAL",
  SEPA = "SEPA",
}

export interface BillingAddress {
  firstName?: string;
  lastName?: string;
  name?: string;
  country: string;
  city: string;
  region: string;
  address1: string;
  address2?: string;
  zipcode: string;
}

export enum accordionStates {
  IN_PROGRESS = "IN_PROGRESS",
  LOCKED = "LOCKED",
  COMPLETE = "COMPLETE",
  ERROR = "ERROR",
}

export type AccordionInfo = {
  name: string;
  opened: boolean;
  nextAccordion?: string;
  showContinueButton?: boolean;
  step: number;
  state: accordionStates;
};

export const accordionsInitialState: Record<string, AccordionInfo> = {
  personalizedCourse: {
    name: "personalizedCourse",
    opened: true,
    nextAccordion: "paymentInfo",
    showContinueButton: true,
    state: accordionStates.IN_PROGRESS,
    step: 1,
  },
  paymentInfo: {
    name: "paymentInfo",
    opened: false,
    nextAccordion: "accountInfo",
    showContinueButton: true,
    state: accordionStates.LOCKED,
    step: 2,
  },
  accountInfo: {
    name: "accountInfo",
    opened: false,
    nextAccordion: "reviewTermsAndSubmit",
    showContinueButton: true,
    state: accordionStates.LOCKED,
    step: 3,
  },
  reviewTermsAndSubmit: {
    name: "reviewTermsAndSubmit",
    opened: false,
    showContinueButton: false,
    state: accordionStates.LOCKED,
    step: 4,
  },
};

export type AccordionType = keyof typeof accordionsInitialState;

export interface CheckoutState {
  salesTaxAmount: number;
  salesTaxState: string;
  salesTaxRate: number;
  trialFeeSalesTaxAmount: number;
  trialFeeSalesTaxRate: number;
  paypalAccount: string;
  paypalError?: string;
  paymentType?: paymentTypes;
  purchaseErrorCode?: errorConstants | Error | "";
  processing: boolean;
  showCourseEnhancementEdit: boolean;
  goalMonth: string;
  // TODO(patrick): Move these modal states out of redux and into the component states if possible.
  madeTrialPriceChoice: boolean;
  isTimerExpired: boolean;
  isTaxInclusive: boolean;
  isTrialFeeTaxInclusive: boolean;
  walletPay: boolean;
  applePay: boolean;
  googlePay: boolean;

  billingName?: string;
  billingAddress?: BillingAddress;
  billingAddressErrors?: {
    COUNTRY: boolean;
    CITY: boolean;
    REGION: boolean;
    ADDRESS1: boolean;
    ZIPCODE: boolean;
  };

  showInvalidAgeModal?: boolean;
  showEnrollmentErrors?: boolean;
  showPaymentInfoErrors?: boolean;
  showReviewErrors?: boolean;

  stripeError?: Record<string, boolean | string>;
  sepaError?: Record<string, boolean | string>;
  // Used to handle any changes in the stripe universal form
  stripeUniversal: {
    // Sets the form to read-only.
    // The new universal payments approach is to create a payment intent first, then charge it.
    // Since our backend handles email validation after a payment intent is created, the frontend
    // will disable the form so they can't make changes to their payment method unless they refresh
    // to avoid edge case behaviours.
    formLocked: boolean;
  };

  userStartedEnteringPaymentInfo: boolean;

  multiUserPlan: {
    toggled: boolean;
    secondaryUserSameAddressConfirmation?: boolean;
  };

  paymentAddonsInCart: PaymentAddonProduct[];

  accordions: Record<AccordionType, AccordionInfo>;
  currentAccordion: string;

  acceptedClinicalOffer: boolean;
}

export const initialState: CheckoutState = {
  salesTaxAmount: 0,
  salesTaxState: "",
  salesTaxRate: 0,
  trialFeeSalesTaxAmount: 0,
  trialFeeSalesTaxRate: 0,
  paypalAccount: "",
  processing: false,
  showCourseEnhancementEdit: false,
  goalMonth: "",
  madeTrialPriceChoice: false,
  isTimerExpired: false,
  isTaxInclusive: false,
  isTrialFeeTaxInclusive: false,
  walletPay: false,
  applePay: false,
  googlePay: false,
  userStartedEnteringPaymentInfo: false,
  stripeUniversal: { formLocked: false },
  multiUserPlan: {
    toggled: false,
    secondaryUserSameAddressConfirmation: null,
  },
  paymentAddonsInCart: [],
  currentAccordion: "personalizedCourse",
  accordions: accordionsInitialState,
  acceptedClinicalOffer: false,
};

const checkoutSlice = createSlice({
  name: "checkout",
  initialState,
  reducers: {
    /* eslint-disable no-param-reassign */
    updateCheckoutState(state, action: PayloadAction<Partial<CheckoutState>>) {
      return { ...state, ...action.payload };
    },
    addPaymentAddonsToCart(
      state,
      action: PayloadAction<PaymentAddonProduct[]>
    ) {
      action.payload.forEach((addon) => {
        if (!state.paymentAddonsInCart.includes(addon)) {
          state.paymentAddonsInCart.push(addon);
        }
      });
    },
    removePaymentAddonsFromCart(
      state,
      action: PayloadAction<PaymentAddonProduct[]>
    ) {
      state.paymentAddonsInCart = state.paymentAddonsInCart.filter(
        (addon) => !action.payload.includes(addon)
      );
    },
    acceptClinicalOffer(state, action: PayloadAction<boolean>) {
      return { ...state, acceptedClinicalOffer: action.payload };
    },
    resetCheckoutTaxData(state) {
      return {
        ...state,
        salesTaxAmount: 0,
        salesTaxState: "",
        salesTaxRate: 0,
        trialFeeSalesTaxAmount: 0,
        trialFeeSalesTaxRate: 0,
        isTaxInclusive: false,
        isTrialFeeTaxInclusive: false,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(failCountryValidation, (state) => {
        state.paymentAddonsInCart = state.paymentAddonsInCart.filter(
          (addon) => addon !== "noomClinical"
        );
        state.acceptedClinicalOffer = false;
      })
      .addCase(failStateValidation, (state) => {
        state.paymentAddonsInCart = state.paymentAddonsInCart.filter(
          (addon) => addon !== "noomClinical"
        );
        state.acceptedClinicalOffer = false;
      })
      .addCase(userAcceptedSuggestedAddress, (state, action) => {
        const { isSameAsBillingAddress, suggestedAddress } = action.payload;
        if (isSameAsBillingAddress) {
          Object.assign(state.billingAddress, suggestedAddress);
        }
      })
      .addCase(failNoomClinicalAgeValidation, (state, action) => {
        const { isMedTierEnabled } = action.payload;
        if (!isMedTierEnabled) {
          state.paymentAddonsInCart = state.paymentAddonsInCart.filter(
            (addon) => addon !== "noomClinical"
          );
          state.acceptedClinicalOffer = false;
        }
      })
      .addCase(failMedicareStatusValidation, (state) => {
        state.paymentAddonsInCart = state.paymentAddonsInCart.filter(
          (addon) => addon !== "noomClinical"
        );
        state.acceptedClinicalOffer = false;
      });
    /* eslint-enable no-param-reassign */
  },
});

export const {
  updateCheckoutState,
  addPaymentAddonsToCart,
  removePaymentAddonsFromCart,
  acceptClinicalOffer,
  resetCheckoutTaxData,
} = checkoutSlice.actions;

export default checkoutSlice;
